import { useLastVisitedOrganization } from "@custom-hooks/organizations";
import { ApplicationLayout } from "@layouts/ApplicationLayout/ApplicationLayout";
import { useRouter } from "next/router";
import { useEffect } from "react";
const Default = () => {
  const router = useRouter();
  const {
    getRedirectUrlForLastVisitedOrganization
  } = useLastVisitedOrganization();
  useEffect(() => {
    const redirectUrl = getRedirectUrlForLastVisitedOrganization();
    if (redirectUrl) {
      router.replace(redirectUrl);
    }
  }, [getRedirectUrlForLastVisitedOrganization]);
  return null;
};
Default.getLayout = function getLayout(page: React.ReactNode) {
  return <ApplicationLayout>{page}</ApplicationLayout>;
};
export default Default;